<template>
  <v-window :value="parseInt(step) - 1" vertical v-if="data.Step">
    <v-window-item :key="1">
      <consolidatedReportStep1
        ref="step1"
        @update:loading="$emit('update:loading', $event)"
        @update:isUnsaved="$emit('update:isUnsaved', $event)"
        :readonly="readonly"
      />
    </v-window-item>
    <v-window-item :key="2">
      <consolidatedReportStep2
        ref="step2"
        v-bind="$attrs"
        @update:loading="$emit('update:loading', $event)"
        @update:isUnsaved="$emit('update:isUnsaved', $event)"
        :readonly="readonly"
      />
    </v-window-item>
    <v-window-item :key="3">
      <consolidatedReportStep3
        ref="step3"
        v-bind="$attrs"
        @update:loading="$emit('update:loading', $event)"
        @update:isUnsaved="$emit('update:isUnsaved', $event)"
        :readonly="readonly"
      />
    </v-window-item>
    <v-window-item :key="4">
      <consolidatedReportStep4
        ref="step4"
        v-bind="$attrs"
        @update:loading="$emit('update:loading', $event)"
        @update:isUnsaved="$emit('update:isUnsaved', $event)"
        :readonly="readonly"
      />
    </v-window-item>
    <v-window-item :key="5">
      <consolidatedReportStep5
        ref="step5"
        v-bind="$attrs"
        @update:loading="$emit('update:loading', $event)"
        @update:isUnsaved="$emit('update:isUnsaved', $event)"
        :readonly="readonly"
      />
    </v-window-item>
    <v-window-item :key="6">
      <consolidatedReportStep6
        ref="step6"
        v-bind="$attrs"
        @update:loading="$emit('update:loading', $event)"
        @update:isUnsaved="$emit('update:isUnsaved', $event)"
        :readonly="readonly"
      />
    </v-window-item>
    <v-window-item :key="7">
      <consolidatedReportStep7
        ref="step7"
        v-bind="$attrs"
        @update:loading="$emit('update:loading', $event)"
        @update:isUnsaved="$emit('update:isUnsaved', $event)"
        :readonly="readonly"
      />
    </v-window-item>
  </v-window>
</template>
<script>
export default {
  name: "consolidated-report-steps",
  components: {
    consolidatedReportStep1: () => import("./step1/index.vue"),
    consolidatedReportStep2: () => import("./step2/index.vue"),
    consolidatedReportStep3: () => import("./step3/index.vue"),
    consolidatedReportStep4: () => import("./step4/index.vue"),
    consolidatedReportStep5: () => import("./step5/index.vue"),
    consolidatedReportStep6: () => import("./step6/index.vue"),
    consolidatedReportStep7: () => import("./step7/index.vue"),
  },

  props: {
    step: {
      type: [String, Number],
      default: "1",
    },
    data: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
